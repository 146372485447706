/* Light Mode (Default) */
.homepage-container.light {
  background-color: #ffffff;
  color: #333;
  transition: background 0.3s ease, color 0.3s ease;
}

/* Dark Mode */
.homepage-container.dark {
  background-color: #121212;
  color: #f5f5f5;
  transition: background 0.3s ease;
}

/* Background Image Handling */
.bg {
  width: 100%;
  height: 100vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: fixed;
  filter: brightness(0.2);
}

/* Ensures Navbar does not overlap the content */
.Homepage {
  position: relative;
  text-align: center;
  padding-top: 80px; /* Ensure content does not go under the navbar */
}

/* Title Styling */
#title {
  text-align: left;
  padding-left: 10%;
  padding-right: 10%;
}

/* Large Heading */
#titleText {
  font-weight: 900;
  font-size: 60pt;
  line-height: 95%;
}

/* Paragraph Styling */
#paraText {
  text-align: left;
  padding-left: 10%;
  padding-right: 10%;
  color: #888; /* Default for light mode */
}

/* Dark Mode Adjustments */
.homepage-container.dark #paraText {
  color: #bbb; /* Lighter text for dark mode */
  transition: background 0.3s ease;
}

.homepage-container.dark #titleText {
  transition: background 0.3s ease;
  color: #f5f5f5;
}

/* CTA Button */
#ctamsg {
  font-weight: 700;
  text-align: center;
  color: white;
  padding: 10px 40px;
  background-color: black;
  border-radius: 10px;
  font-size: 1.5rem;
  text-decoration: none;
}

/* CTA Button Dark Mode */
.homepage-container.dark #ctamsg {
  background-color: #ffffff;
  color: #121212;
}

/* Store Buttons */
.store-logos {
  /* margin: 1rem; */
  width: 100px;
  height: 100px;
  margin-top: 1rem;
}

.store-logo {
  /* margin: 1rem; */
  width: 100px;
  height: 100px;

}
.play-app-btn{
  margin-top: 1rem;
}
/* Feedback Link */
.testMsg {
  text-align: center;
  color: #000;
  font-size: 12pt;
  text-decoration: none;
  font-weight: 500;
}

/* Dark Mode Feedback Link */
.homepage-container.dark .testMsg {
  color: #f5f5f5;
}

/* Mobile View */
@media only screen and (max-width: 700px) {
  #ctamsg {
    font-size: 1.1rem;
    padding: 10px 40px;
    width: 7rem;
  }

  #title {
    font-weight: 700;
    padding-left: 5%;
  }

  #titleText {
    font-size: 2.6rem;
    padding-bottom: 1rem;
  }

  #paraText {
    /* padding-left: 5%; */
    font-size: 0.9rem;
  }
  .homepage-title{
    font-size: 2rem !important;
  }
  .homepage-subtitle{
    font-size: 1rem !important;
  }
}
