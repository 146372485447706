.recipe-details{
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; */
    margin: 10% 20% 0 20%;
    /* background-color: #f1f1f1; */
}

.tabs-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

/* Tab Button Styling */
.tab-btn {
    padding: 10px 15px;
    border: none;
    background-color: var(--tab-bg);
    color: var(--text-color);
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;
    position: relative;
}

.tab-content{
    margin-bottom: 20px;

}



/* Active Tab with Green Underline */
.tab-btn.active {
    font-weight: bold;
}

/* Green Underline */
.tab-btn.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    height: 3px;
    background-color: green;
    border-radius: 5px;
}





.dark .tabs-container{
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #222;
    color: #f1f1f1;
}

.light .tabs-container{
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* background-color: #f1f1f1 !important; */
    color: #333;
}

.dark .tabs-container button {
    background-color: #222 !important;
    color: #f1f1f1 !important;
    border: none;
    padding: 1rem;
    font-weight: 600;
}

.light .tabs-container button {
    /* background-color: #c8c4c4 !important; */
    color: #333 !important;
    border: none;
    padding: 1rem;
    font-weight: 600;
}

.dark .tabs-container button {
    background-color: #fff;
    color: #333;
}

.view-recipe-image{
    width: 100%;
    max-height: 350px;
}

.recipe-details{
    width: 0% 20% 0% 20%;
}


.ingredients-data{
    /* border: 1px solid green; */
    padding: 1rem;
}


.dark.nutrition-container {
    width: 250px;
    background-color: black;
    color: white;
    padding: 10px;
    border: 2px solid white;
    font-family: Arial, sans-serif;
    
    
}

.dark.nutrition-title {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 2px solid white;
    padding-bottom: 5px;
    text-align: left;
}

.nutrition-serving {
    font-size: 14px;
    margin-top: 5px;
}

.dark.nutrition-calories {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid white;
    padding-bottom: 5px;
}

.dark hr{
    height: 5px;
    background-color: #fff;
}

.light hr{
    height: 5px;
    background-color: #333;
}

.nutrition-table {
    width: 100%;
    margin-top: 10px;
    border: 3px solid white !important;
    padding: 0.5rem;
}

.light .nutrition-table {
    width: 100%;
    margin-top: 10px;
    border: 3px solid #333 !important;
    padding: 0.5rem;
}

.nutrition-table tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid white;
    padding: 5px 0;
}

.light .nutrition-table tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #333;
    padding: 5px 0;
}

.nutrient-name {
    font-size: 14px;
    flex: 2;
}

.nutrient-value {
    font-size: 14px;
    flex: 1;
    font-weight: bold;
}

.nutrient-percent {
    font-size: 14px;
    flex: 1;
    text-align: right;
}







/* Main container */
.view-recipe-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* ========== LIGHT MODE ========== */
  body.light .view-recipe-container {
    background-color: #fff;
    color: #333;
  }
  
  body.light .view-recipe-title {
    color: #222;
  }
  
  body.light .view-recipe-author {
    color: #666;
  }
  
  /* ========== DARK MODE ========== */
  body.dark .view-recipe-container {
    background-color: #222;
    color: #f1f1f1;
  }
  
  body.dark .view-recipe-title {
    color: #f8f8f8;
  }
  
  body.dark .view-recipe-author {
    color: #bbb;
  }
  
  /* Recipe Image */
  .view-recipe-image {
    width: auto !important;
    /* max-width: 500px; */
    height: auto;
    /* max-height: 400px; */
    /* object-fit: cover; */
    /* margin-bottom:1rem !important; */
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  

.tableRows{
    border: 1px solid red;
}


  /* ========== INGREDIENTS & INSTRUCTIONS ========== */
  .view-recipe-section {
    margin-top: 20px;
  }
  
  .view-recipe-section h3 {
    font-size: 1.4rem;
    margin-bottom: 10px;
  }
  
  /* Ingredient List */
  .view-recipe-ingredients {
    list-style-type: none;
    padding: 0;
  }
  
  .view-recipe-ingredients li {
    padding: 8px 12px;
    margin: 5px 0;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  /* Light Mode Ingredients */
  body.light .view-recipe-ingredients li {
    background-color: #f5f5f5;
    color: #333;
  }
  
  /* Dark Mode Ingredients */
  body.dark .view-recipe-ingredients li {
    background-color: #444;
    color: #fff;
  }
  
  /* Instructions */
  .view-recipe-instructions {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 15px;
    border-radius: 8px;
    font-size: 1rem;
  }
  
  /* Light Mode Instructions */
  body.light .view-recipe-instructions {
    background-color: #f9f9f9;
    color: #333;
  }
  
  /* Dark Mode Instructions */
  body.dark .view-recipe-instructions {
    background-color: #333;
    color: #f1f1f1;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .view-recipe-container {
      width: 90%;
    }
  }
  


  /* /////////////////////////////////////////////////// responsive */


@media only screen and (max-width:800px){

    
  .recipe-details{
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; */
    margin: 20% 1rem 0 1rem;
    /* background-color: #f1f1f1; */
}

.view-recipe-image {
    width: 100% !important;
    /* max-width: 500px; */
    height: auto;
    /* max-height: 400px; */
    /* object-fit: cover; */
    /* margin-bottom:1rem !important; */
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }

  .tabs-container {
    display: flex;
    gap: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 0.5rem 0rem !important;
}

.tab-btn {
    padding: 10px 10px;
    border: none;
    background-color: var(--tab-bg);
    color: var(--text-color);
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;
    position: relative;
}

.tab-content{
  
    transform: translateX(00px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}
}
