#changelog{
    /* background-color: white; */

    text-align: center;

}

li{
    font-size: 9px;
}

/* Default (light) theme */
/* Default Light Theme */
body.light {
    background-color: #ffffff;
    color: #333;
    transition: background 0.3s ease;
  }
  
  /* Dark Theme */
  body.dark {
    background-color: #121212;
    color: #f5f5f5;
    transition: background 0.3s ease;
  }
  