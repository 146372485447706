


  /* Light Mode Instructions */
  body.light .view-recipe-instructions {
    background-color: #f9f9f9;
    color: #333;
  }
  
  /* Dark Mode Instructions */
  body.dark .view-recipe-instructions {
    background-color: #333;
    color: #f1f1f1;
  }


  .rightlrft{
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
  }

  .footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0rem 0rem;
    padding: 2rem;
  }

body.dark .footer{
    background-color: #222 !important;
    color: #f1f1f1;
}

body.light .footer{
    background-color: #f0f0f0;
    color: #333;
}


  .logo-div, .quick-links, .contact{
    flex: 1;
  }

  .quick-links, .contact{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact, .quick-links{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
   gap: 2rem;
  }


  .contact ul li, .quick-links ul li{
    list-style: none;
    /* padding: 1rem !important; */
  }
 
  .contact ul li a, .quick-links ul li a{
font-size: 1rem;
text-decoration: none;
/* padding: 3rem; */
  }

  .contact ul li, .quick-links ul li{
    gap: 2rem !important;
  }

  body.dark  .contact ul li a, .quick-links ul li a{
    color: #f0f0f0;
  }




  /* ///////////////////////////////////////////////////////////////////////////////// 
  
  */



section {
  width: 100%;
  display: inline-block;
  background: #333;
  height: 50vh;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  text-decoration: underline;
}

.footer-distributed{
	
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: bold 16px sans-serif;
	padding: 55px 50px;
}

.dark .footer-distributed{
    background: #222;
    color: #f0f0f0;
}

.light .footer-distributed{
    background: #f0f0f0;
    color: #222;
}


.dark .footer-distributed span{
    background: #222;
    color: #f0f0f0;
}

.dark .footer-distributed  p{
    background: #222;
    color: #f0f0f0;
}

.light .footer-distributed p{
    background: #f0f0f0;
    color: #222;
}

.light .footer-distributed p a{
    background: #f0f0f0;
    color: #222;
}


.light .footer-distributed p span{
    background: #f0f0f0;
    color: #222 !important;
}

.light .footer-distributed p{
    background: #f0f0f0;
    color: #222 !important;
}

.footer-company-about{
    background: #f0f0f0;
    color: #222 !important;
}

.qlhead{
  display: flex;
  justify-content: flex-start;
  align-items: center;

}

.logo-name{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.logo-name h3{
    font-size: 0.5rem;
    
}


.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display: inline-block;
	vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left{
	width: 40%;
}

/* The company logo */

.footer-distributed h3{
	color:  #ffffff;
	font: normal 36px 'Open Sans', cursive;
	margin: 0;
}

.footer-distributed h3 span{
	color:  lightseagreen;
}

/* Footer links */

.footer-distributed .footer-links{
	color:  #ffffff;
	margin: 20px 0 12px;
	padding: 0;
}

.footer-distributed .footer-links a{
	display:inline-block;
	line-height: 1.8;
  font-weight:400;
	text-decoration: none;
	color:  inherit;
}

.footer-distributed .footer-company-name{
	color:  #222;
	font-size: 1.2rem;
	font-weight: normal;
	margin-top: 1rem;
}

.footer-company-about{
  font-weight: normal;
 
}

.footer-company-about p{
  font-weight: normal;
 margin-top: 1rem;
}

.rightlrft .qlleft a, .qlright a{
  margin-top: 1rem;
}
/* Footer Center */

.footer-distributed .footer-center{
	width: 35%;
}

.footer-distributed .footer-center i{
	background-color:  #33383b;
	color: #ffffff;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope{
	font-size: 17px;
	line-height: 38px;
}

.footer-distributed .footer-center p{
	display: inline-block;
	color: #ffffff;
  font-weight:400;
	vertical-align: middle;
	margin:0;
}

.footer-distributed .footer-center p span{
	display:block;
	font-weight: normal;
	font-size:14px;
	line-height:2;
}

.footer-distributed .footer-center p a{
	/* color:  lightseagreen; */
	text-decoration: none;;
  display: flex;
}


.appDownloadlink{
  display: flex;
  gap: 1rem;
}

.appstr{
  width: 100px;
  height: 100px !important;
}

/* .footer-distributed .footer-links a:before {
  content: "|";
  font-weight:300;
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
} */

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right{
	width: 20%;
}

/* .footer-distributed .footer-company-about{
	line-height: 20px;
	color:  #92999f;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
} */

.quicklinks-head{
  font-size: 1.5rem;
  font-weight: bold !important;
}

.contact-us-footr{
  font-size: 1.5rem !important;
  font-weight: bold !important;
}


.footer-distributed .footer-company-about span{
	display: block;
	color:  #ffffff;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

.footer-distributed .footer-icons{
	margin-top: 25px;
}

.footer-distributed .footer-icons a{
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color:  #33383b;
	border-radius: 2px;

	font-size: 20px;
	color: #ffffff;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}



/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {

	.footer-distributed{
		font: bold 14px sans-serif;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
		margin-bottom: 40px;
		text-align: center;
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
	}

  .logo-name{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
}

.footer-distributed{
padding: 1rem;
}

.quiclinksfooter{
  width: 100;
}
.footer-links{
width: 100%;
display: flex;
}
.qlright a, .qlleft a{
text-decoration: underline !important;

}
.appDownloadlink{
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-right{
  text-align: start;
  width: 100%;
  display: flex;
  margin-bottom: 0px !important;
}
.footer-company-about{
  text-align: start;
  width: 100%;
  display: flex; 
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.appstr{
  width: 150px;
  height: 150px;
}
}
