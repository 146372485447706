
.privacy-container.light {
    background-color: #ffffff;
    color: #000000;
}

/* Dark Theme */
.privacy-container.dark {
    background-color: #121212;
    color: #ffffff;
}

/*  Main Privacy Policy Section  */
#privacyParent {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-left: 10%;
    margin-right: 10%;
}

/*  Section Borders Adapted to Theme  */
.privacy-container.light #summaryKeyPoints {
    border: 1px solid orange;
    padding: 1rem;
}

.privacy-container.dark #summaryKeyPoints {
    border: 1px solid rgb(255, 165, 0); /* Adjusted orange for dark mode */
    padding: 1rem;
}

.privacy-container.light #privacyQuestions {
    border: 1px solid green;
    padding: 1rem;
}

.privacy-container.dark #privacyQuestions {
    border: 1px solid lightgreen;
    padding: 1rem;
}

#privacyAnswers {
    padding: 2%;
}

/* ========== Collapsible Section ========== */
.collapsible {
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Light Theme Collapsible */
.privacy-container.light .collapsible {
    background-color: #f1f1f1;
    color: black;
}

.privacy-container.light .collapsible:hover {
    background-color: #ddd;
    color: #333;
}

/* Dark Theme Collapsible */
.privacy-container.dark .collapsible {
    background-color: #333;
    color: white;
}

.privacy-container.dark .collapsible:hover {
    background-color: #444;
    color: #ddd;
}

/* Collapsible Icon Adjustment */
.collapsible-icon {
    float: right;
    transition: transform 0.3s ease;
}

.collapsible-icon.open {
    transform: rotate(180deg);
}

/* ========== Responsive Design ========== */
@media screen and (max-width: 768px) {
    #privacyParent {
        margin-left: 5%;
        margin-right: 5%;
    }

    .collapsible {
        font-size: 14px;
        padding: 15px;
    }
}
