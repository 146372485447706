body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #app, #app>div {
  height: 100%;
  width: 100%;
}


/* Default (light) theme */
/* Default Light Theme */
body.light {
  background-color: #ffffff;
  color: #333;
  transition: background 0.3s ease;
}

/* Dark Theme */
body.dark {
  background-color: #121212;
  color: #f5f5f5;
  transition: background 0.3s ease-in-out;
}


