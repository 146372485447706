/* Ensure header doesn't overlap */
.recipe-page {
    padding-top: 80px;
  }
  
  /* Centered heading */
  .upperSection {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Search Box */
  .search-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .smalltext{
    margin: 0rem 0.5rem;
  }
  .search-box {
    display: flex;
    align-items: center;
    background-color: rgb(207, 201, 201);
    border-radius: 30px;
    padding: 0.5rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 27rem;
    margin: 0rem 0.5rem;
  }
  
  .search-input {
    border: none;
    background: transparent;
    padding: 1rem;
    flex: 1;
    font-size: 1rem;
    outline: none;
  }
  
  .search-icon {
    color: gray;
    margin-right: 10px;
  }
  
  /* Search Results */
  .search-results {
    max-width: 400px;
    background: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-radius: 10px;
    max-height: 300px;
    overflow-y: auto;
    margin: 10px auto;
    
  }
  
.recipe-item{
    display: block;
    padding: 0.3rem;
    cursor:pointer;
    border-radius: 5px;
}

body.dark .recipe-item:hover{
    background-color:#3e3d3d ;
}

  /* Recipe Grid */
  .recipes-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    width: 95%;
    margin: 2rem auto;

  }
  
  /* Recipe Card */
  .recipe-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f9f9f9;
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    cursor: pointer;
  }
  
  .recipe-image {
    width: 100%;
    max-width: 180px;
    height: 120px;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .recipe-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
  }
  
  /* Light & Dark Mode */
  body.light .search-box, body.light .recipe-card {
    background-color: #f0f0f0;
    color: #333;
caret-color: #333;
  }
  
  body.light .search-results {
    background-color: #fff;
    color: #333;
  }
 
  body.dark .search-input {
    color: white;
  }
  
  body.dark .search-icon{
    color: #00d66f;
    background-color: #222222;
    border: none;
    box-shadow: none;
  }
  body.light .search-icon{
    color: #00d66f;
    background-color: #f0f0f0;
    border: none;
    box-shadow: none;
  }


  body.dark .search-box, body.dark .recipe-card {
    background-color: #222;
    color: #f1f1f1;
    caret-color: #fff;
  }
  
  body.dark .search-results {
    background-color: #222;
    color: #f1f1f1;
  }
  
  /* Responsive Grid */
  @media (max-width: 1024px) {
    .recipes-container {
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      width: 98%;
    }
  }
  
  @media (max-width: 768px) {
    .recipes-container {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
  }
  
  @media (max-width: 480px) {
    .recipes-container {
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }
  }
  
  