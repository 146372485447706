/* General Styles */
.header {
    width: 100%;
    padding: 15px 20px;
    position: fixed;
    top: 0;
    left: 0;
    /* height: 60px; */
    z-index: 1000;
    transition: background 0.3s ease;
    background-color: var(--header-bg);
  }
  

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    margin: 0;
  }
  
 
  .nav-links {
    display: flex;
    gap: 20px;
  }
  
  .nav-links a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  

  body.light .header {
    background-color: #ffffff;
  }
  
  body.light .nav-links a {
    color: #333 !important; /* Black text for nav links in light mode */
  }
  
  body.light .icon {
    color: #333 !important; /* Dark icons in light mode */
  }
  
  body.light .mobile-menu {
    background-color: #f9f9f9;
  }
  
  /* Dark Mode */
  body.dark .header {
    background-color: #121212;
  }
  
  body.dark .nav-links a {
    color: #ffffff !important; /* White text for nav links in dark mode */
  }
  
  body.dark .icon {
    color: #ffffff !important; /* White icons in dark mode */
  }
  
  body.dark .mobile-menu {
    background-color: #222;
  }

  body.dark .mobile-menu a {
    color: #ffffff !important; /* White text in dark mode */
  }
  
  body.light .mobile-menu a {
    color: #333 !important; /* Dark text in light mode */
  }
  
  /* Mobile Menu */
  .mobile-menu {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 15px;
    transition: background 0.3s ease;
  }
  
  .mobile-menu a {
    color: inherit !important;
    padding: 10px;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.3s ease;
  }
  
  /* Theme Toggle Button */
  .theme-toggle {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  /* Mobile Menu Button */
  .menu-toggle {
    background: none;
    border: none;
    cursor: pointer;
    display: none;
  }
  
  /* Responsive Design */
  @media (max-width: 800px) {
    .nav-links {
      display: none;
    }
    .menu-toggle {
      display: block;
    }
  }
  