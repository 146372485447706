* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.blogs-container.light{
  background-color: #fff;
  color:#424242;
  transition: background 0.3s ease color 0.3s ease;
}

.blogs-container.dark{
  background-color: #121212;
  color: #f5f5f5;
  transition: background 0.3s ease;
}


.blogs-sec {
  /* background-color: #fff; */
  padding: 0rem 20rem;
  padding-bottom: 5rem;
}

.blog-text {
  /* margin: 0rem 20rem; */
  text-align: start;
  margin-top: 2rem;
}
.blog-heading {
  text-align: start;
  padding: 3rem 0rem 1.5rem 0rem;
}
.blog-text p {
  margin-top: 0.5rem;
}
.Name-sharebtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.name-description {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}

.Image {
  background-image: url("./images/blogs/blog_1.jpg");
  height: 15rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  /* width: 100%; */
}
.textinimg {
  position: absolute;
  top: 80%;
  right: 1.5rem;
  font-size: 1.5rem;
  font-weight: 800;
  color: #424242;
}
.share-icon span {
  padding: 0rem 0rem 0rem 0.5rem;
}

  @media
  only
  screen
  and
  (max-width: 950px) {
  .blogs-sec {
    /* background-color: #fff; */
    padding: 0rem 2rem;
    padding-bottom: 5rem;
    
  }
  .Image {
    background-image: url("./images//blogs//blog_1.jpg");
    /* height: 15rem; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%;
    object-fit: cover;
  }
  .textinimg {
    position: absolute;
    top: 85%;
    right: 0.5rem;
    font-size: 1rem;
    font-weight: 800;
    color: #424242;
  }
}
